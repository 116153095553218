import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore/';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MetaModule, MetaService } from '@ngx-meta/core';
import { SAVER, getSaver } from './saver.provider';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    MaterialModule,
    MatFormFieldModule,
    AngularFireModule.initializeApp(environment.firebase,{useFetchStreams: false}),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    MatInputModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireAuthGuardModule,
    MetaModule.forRoot(),
    BrowserAnimationsModule,
    CommonModule,
    BrowserTransferStateModule,
    HttpClientModule,
  ],
  providers: [MetaService, {provide: 'isBrowser', useValue: true}, {provide: SAVER, useFactory: getSaver}],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
