// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // firebase: {
  //   apiKey: "AIzaSyAto-MNfceQJzKYcENfZYY_ga-mkY3atDk",
  //   authDomain: "jumpman-production.firebaseapp.com",
  //   projectId: "jumpman-production",
  //   storageBucket: "jumpman-production.appspot.com",
  //   messagingSenderId: "551803320316",
  //   appId: "1:551803320316:web:cb03963562c119c7936479",
  //   measurementId: "G-15XNFFMGDV"
  // }
  firebase: {
    apiKey: "AIzaSyDHcKHq2NZEIxt061CFwGmdB_GY123mV_I",
    authDomain: "jumpman-staging-e302a.firebaseapp.com",
    projectId: "jumpman-staging-e302a",
    storageBucket: "jumpman-staging-e302a.appspot.com",
    messagingSenderId: "599516921851",
    appId: "1:599516921851:web:715540559ec59bb1e4d55e",
    measurementId: "G-M45K98ZHV3"
  }
  // firebase: {
  //   apiKey: "AIzaSyBxUo3nLeZrn5qJ8WgKS0xqhUYpiF9SO_Y",
  //   authDomain: "leaderboard-c5ce2.firebaseapp.com",
  //   projectId: "leaderboard-c5ce2",
  //   storageBucket: "leaderboard-c5ce2.appspot.com",
  //   messagingSenderId: "43520617364",
  //   appId: "1:43520617364:web:897e3c2611b1cdd156bd81",
  //   measurementId: "G-GR7QRMN8VK"
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
